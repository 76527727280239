import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { BaseStyles, Box, Alert } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "basestyles"
    }}>{`BaseStyles`}</h1>
    <p>{`Loads global styles and set up the styled-components theme by wrapping it in `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{`.`}</p>
    <Alert variant="info" noAnimation mdxType="Alert">
  Wrap your entire app with this component, and only use it once.
    </Alert>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={1} __code={'<BaseStyles colorKey=\"red01\" font=\"urban-grotesk\">\n  <Box color=\"primary\">Your app goes here</Box>\n</BaseStyles>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      BaseStyles,
      Box,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BaseStyles colorKey="red01" font="urban-grotesk" mdxType="BaseStyles">
    <Box color="primary" mdxType="Box">Your app goes here</Box>
  </BaseStyles>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={BaseStyles} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      